import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setTheLesson(state, theLesson) {
    state.theLessonCached[theLesson.id] = theLesson
  },

  setLessons(state, payload = []) {
    state.lessons.push(...payload)
  },

  setLessonsPeriod(state, payload) {
    state.lessonsPeriod = {
      start: payload?.start,
      end: payload?.end,
    }
  },

  setLessonsFirstWeek(state, payload = []) {
    state.lessonsFirstWeek = payload
  },
}
