import { RouterUser } from '@/RouterUser'
import { dateToDay, getDaysAfter } from '@/helpers/dateFormatHelper'
import { showToast } from '@/helpers/toast'
import { i18n } from '@/i18n/setup'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiLesson from 'ApiRest/Api/Account/Client/Lesson'
import ApiLessonList from 'ApiRest/Api/Account/Client/Lesson/List'
import { isNil } from 'lodash'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchLessons({ state, commit, rootGetters }) {
    const hasPeriod =
      !isNil(state.lessonsPeriod.start) || !isNil(state.lessonsPeriod.end)

    if (!hasPeriod) {
      const dates = {
        start: dateToDay(),
        end: getDaysAfter(dateToDay()),
      }

      commit('setLessonsPeriod', dates)
    }

    const { data } = await ApiLessonList.get(
      rootGetters['client/students/currentStudentId'],
      {
        date: {
          startDate: state.lessonsPeriod.start,
          endDate: state.lessonsPeriod.end,
        },
      },
    )

    if (state.lessons.length === 0) {
      commit('setLessonsFirstWeek', data)
    }

    commit('setLessons', data)
  },

  async fetchTheLesson({ commit, getters }, id) {
    const lesson = getters.getCurrentLesson(id)

    if (!lesson) {
      try {
        const { data } = await ApiLesson.get(id)

        commit('setTheLesson', data)
      } catch (error) {
        // TODO: -> component
        if (error?.response?.status === HttpStatusCodes.NotFound) {
          showToast(
            i18n.global.t('store.client.toasted_message.lesson_was_not_found'),
            'error',
          )

          RouterUser.router.push({
            name: 'not-found',
          })
        }
      }
    }
  },
}
