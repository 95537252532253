import { hasJustPaidContractDemoFeSimple } from '@/helpers/demoFeSimple/student'
import { hasJustPaidContractFe } from '@/helpers/fe/student'
import { feature } from '@/helpers/feature'
import {
  getFullName,
  getNameAbbreviation,
  getNameWithShortLastName,
} from '@/helpers/fullName'
import Constants from 'Constants'
import { isNil, isNull } from 'lodash'

export default {
  fullName(state) {
    return getFullName(state.profile)
  },

  fullNameWithShortLastName(state) {
    return getNameWithShortLastName(state.profile)
  },

  nameAbbreviation(state) {
    return getNameAbbreviation(state.profile)
  },

  streamAcademicYearPreset(state) {
    return state.profile.streamAcademicYearPreset
  },

  currentAcademicYearPreset(state) {
    return state.profile.currentAcademicYearPreset
  },

  isAvailableAnyCommercialOffer(state) {
    return Boolean(!state.profile.hideOffer)
  },

  isDemoProfile(state) {
    return Boolean(state.profile.isDemo)
  },

  demoAccessTime(state) {
    return Number(state.profile.demoAccessTime)
  },

  demoMessage(state) {
    switch (state.profile.educationType?.id) {
      case Constants.educationType.EXTRAMURAL:
        return state.profile.school?.demoMessage ?? ''

      case Constants.educationType.FAMILY:
        return state.profile.school?.demoMessageFe ?? ''

      default:
    }

    return ''
  },

  hasEducationType(state) {
    return !isNil(state.profile.educationType?.id)
  },

  educationType(state) {
    const { EXTRAMURAL, FAMILY, FULL_TIME } = Constants.educationType
    let id = state.profile.educationType?.id

    const { hasOnlineSchoolOrder } = state.profile

    if (
      hasOnlineSchoolOrder &&
      id !== FAMILY &&
      id !== EXTRAMURAL &&
      id !== FULL_TIME
    ) {
      id = EXTRAMURAL
    }

    return {
      extramural: id === EXTRAMURAL,
      family: id === FAMILY,
      fullTime: id === FULL_TIME,
    }
  },

  isSchoolEducont(state) {
    return Boolean(state.profile.school?.isEducont)
  },

  hasJustPaidContractFe(state) {
    return hasJustPaidContractFe(state.profile)
  },

  hasJustPaidContractDemoFeSimple(state) {
    return hasJustPaidContractDemoFeSimple(state.profile)
  },

  hasAvailablePayment(state, getters, rootState, rootGetters) {
    const isDemoUser = rootGetters['common/user/isDemoUser']
    const { hasAwaitingPayment } = state.profile

    return Boolean(
      (isDemoUser && !getters.hasJustPaidContractDemoFeSimple) ||
        (!isDemoUser && hasAwaitingPayment),
    )
  },

  email(state) {
    return state.profile.email ?? ''
  },

  onlyDiagnostics(state) {
    return state.profile.onlyDiagnostics && feature.isIncomeDiagnostics
  },

  hasClient(state) {
    return Boolean(state.profile.clientEmail)
  },

  isEnrolled(state) {
    return (
      state.profile.enrollmentStatus.id === Constants.enrollmentStatus.ENROLLED
    )
  },

  hasOnlineSchoolOrder(state) {
    return state.profile?.hasOnlineSchoolOrder
  },

  timezonesFormatted(state) {
    return [
      ...state.options.timezones,
    ].sort((a, b) => b.offset - a.offset)
  },

  timezone(state) {
    return state.profile.timezone
  },

  timezoneDefault(state) {
    return state.options.timezones.find(({ isDefault }) => isDefault)
  },

  hasTimezone(state) {
    return !isNull(state.profile.timezone)
  },
}
