export default () => ({
  lessons: [],
  lessonsPeriod: {
    start: null,
    end: null,
  },
  lessonsPreview: [],
  closestLessons: [],
  closestLessonsPeriod: {
    start: null,
    end: null,
  },
  currentLesson: null,
  currentContent: null,
})
