import { RESET_STATE } from '@/helpers/ResetState'
import createState from '@/store/student/journal/state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setLessons(state, payload = []) {
    state.lessons.push(...payload)
  },

  setLessonsPeriod(state, payload) {
    state.lessonsPeriod = {
      start: payload?.start,
      end: payload?.end,
    }
  },

  setLessonsPreview(state, lessons) {
    state.lessonsPreview = lessons
  },

  setTheLesson(state, theLesson) {
    state.currentLesson = theLesson
  },

  setTheLessonIsPassed(state, { lessonId, isPassed }) {
    const lesson = state.lessons.find((i) => i.id === lessonId)

    if (lesson) {
      lesson.isPassed = isPassed
    }
  },

  setTheContent(state, content) {
    state.currentContent = content
  },

  setTheContentIsPassed(state, { contentId, isPassed }) {
    const { topics } = state.currentLesson

    topics.forEach((topic) => {
      const content = topic.contents.find((i) => i.id === contentId)

      if (content) {
        content.isPassed = isPassed
      }
    })
  },

  setTheMiniTestIsPassed(state, { miniTestId, isPassed }) {
    state.currentLesson.topics.forEach((topic) => {
      const miniTest = topic.miniTests.find((i) => i.id === miniTestId)

      if (miniTest) {
        miniTest.isPassed = isPassed
      }
    })
  },

  updateLessonHomework(state, { checked, taskId }) {
    let homework = null

    if (state.currentLesson.homeworkSolution?.id === taskId) {
      homework = state.currentLesson.homeworkSolution
    }

    if (state.currentLesson.personalHomeworkSolution?.id === taskId) {
      homework = state.currentLesson.personalHomeworkSolution
    }

    if (homework) {
      homework.isChecked = checked
    }
  },

  setClosestLessons(state, payload = []) {
    const convertLessonsToObject = (lessons) =>
      Object.fromEntries(
        lessons.map((lesson) => [
          lesson.id,
          lesson,
        ]),
      )

    const lessons = Object.values({
      ...convertLessonsToObject(state.closestLessons),
      ...convertLessonsToObject(payload),
    }).sort((a, b) => {
      const getDate = (lesson) => {
        if (lesson.$type === 'onlineLesson') {
          return new Date(`${lesson.dateStart} ${lesson.timeStart}`).getTime()
        }

        if (lesson.$type === 'lesson') {
          return new Date(lesson.date).getTime()
        }

        return 0
      }

      return getDate(a) - getDate(b)
    })

    state.closestLessons = lessons
  },

  setClosestLessonsPeriod(state, payload) {
    state.closestLessonsPeriod.start = payload.start
    state.closestLessonsPeriod.end = payload.end
  },
}
